<template>
	<form
		class="user-container"
		autocomplete="false"
		@submit.prevent="onSubmit"
	>
		<div class="user-section user-form">
			<div class="form-group">
				<label for="firstName" class="form-label">
					First Name
				</label>
				<input
					id="firstName"
					v-model="form.first_name"
					required
					type="text"
					name="firstName"
					class="form-input"
					placeholder="First Name"
				/>
			</div>
			<div class="form-group">
				<label for="lastName" class="form-label">
					Last Name
				</label>
				<input
					id="lastName"
					v-model="form.last_name"
					type="text"
					required
					name="lastName"
					class="form-input"
					placeholder="Last Name"
				/>
			</div>
			<div class="form-group">
				<label for="email" class="form-label">
					Email
				</label>
				<input
					id="email"
					v-model="form.email"
					required
					type="email"
					name="email"
					class="form-input"
					placeholder="Email"
				/>
			</div>
			<div class="form-group">
				<label for="confirmEmail" class="form-label">
					Confirm Email *
				</label>
				<input
					id="confirmEmail"
					v-model="form.email_confirmation"
					required
					type="email"
					name="confirmEmail"
					class="form-input"
					placeholder="Confirm Email"
				/>
			</div>
			<user-role-table @roles:change="onRolesChange" />
		</div>
		<div class="user-password-section user-form">
			<div class="form-group">
				<label for="password" class="form-label">
					Password
				</label>
				<input
					id="passowrd"
					v-model="form.password"
					required
					type="password"
					name="password"
					class="form-input"
					placeholder="Password"
				/>
			</div>
			<div class="form-group">
				<label for="passwordConfirm" class="form-label">
					Confirm Password
				</label>
				<input
					id="passwordConfirm"
					v-model="form.password_confirmation"
					required
					type="password"
					name="passwordConfirm"
					class="form-input"
					placeholder="Confirm Password"
				/>
			</div>
			<div v-if="!isValidPassword" class="form-group">
				<span v-if="!hasMinimumLength" class="text-red-500">
					The password must have at least 8 characters</span
				>
				<span v-if="!isValidPassword" class="text-red-500">
					The password confirmation does not match</span
				>
			</div>
			<div class="flex justify-end">
				<button
					type="submit"
					:disabled="!isValidForm"
					class="btn btn-outline-success ml-2"
				>
					<font-awesome-icon
						class="btn-icon"
						:icon="['far', 'paper-plane']"
					/>
					<span class="btn-label">
						Save
					</span>
				</button>
			</div>
		</div>
	</form>
</template>

<script>
import { mapActions } from 'vuex'
import UserRoleTable from '@/components/UserRoleTable.vue'

/**
 * The minimum number of characters the password must have.
 *
 * @type {Number}
 */
const MIN_PASSWORD_LENGTH = 8

/**
 * Get the empty partner form object.
 *
 * @return {Object}
 */
const getFormObject = () => {
	return {
		first_name: '',
		last_name: '',
		email: '',
		email_confirmation: '',
		partners: [],
		password: '',
		password_confirmation: '',
		roles: [],
	}
}

/**
 * The user role value.
 *
 * @type {String}
 */
const USER_ROLE = 'user'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		UserRoleTable,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Determine if the password has the minimum lenght.
		 *
		 * @return {Boolean}
		 */
		hasMinimumLength() {
			return this.form.password.length >= MIN_PASSWORD_LENGTH
		},

		/**
		 * Determine if both emails match.
		 *
		 * @return {Boolean}
		 */
		isValidEmail() {
			return this.form.email === this.form.email_confirmation
		},

		/**
		 * Determine if both emails match.
		 *
		 * @return {Boolean}
		 */
		isValidForm() {
			return this.isValidEmail && this.isValidPassword
		},

		/**
		 * Determine is the password is valid.
		 *
		 * @return {Boolean}
		 */
		isValidPassword() {
			if (!this.form) {
				return false
			}

			const { password, password_confirmation } = this.form

			if (password.length === 0) {
				return true
			}

			return password === password_confirmation && this.hasMinimumLength
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Get the form field names/keys.
		 *
		 * @return {Array}
		 */
		fields() {
			return Object.keys(this.form)
		},

		/**
		 * Fill the form with the partner's information.
		 *
		 * @return {void}
		 */
		fillForm() {
			if (!this.user) {
				return
			}

			this.fields().forEach(key => (this.form[key] = this.user[key]))

			this.form.partners = this.user?.partners.map(partner => {
				return partner.id
			})

			this.form.roles = this.user?.roles.map(role => {
				return {
					id: role.id,
					partner_id: role.partner_id,
				}
			})
		},

		/**
		 * Reset the form back to its initial state.
		 *
		 * @return {void}
		 */
		reset() {
			this.form = getFormObject()
		},

		/**
		 * Handle the roles change event.
		 *
		 * @return {void}
		 */
		onRolesChange(roles) {
			this.form.partners = roles.map(role => role.partner_id)

			this.form.roles = roles.filter(role => role.id !== USER_ROLE)
		},

		/**
		 * Handle the on submit event.
		 *
		 * @return {void}
		 */
		async onSubmit() {
			try {
				const response = await this.create(this.form)

				const user = response.get('data', {})

				this.$alert.response(response)

				this.$router.push({
					name: 'app.users.show',
					params: {
						id: user.id,
					},
				})
			} catch (e) {
				this.$alert.response(e)
			}
		},

		...mapActions({
			create: 'users/create',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'UserCreateForm',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the current route for active partner changes.
		 *
		 * @param {Object} to
		 * @param {Object} from
		 * @return {void}
		 */
		$route(to, from) {
			if (to.params.partner !== from.params.partner) {
				this.reset()

				this.$router.push({
					name: 'app.users',
				})
			}
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			form: getFormObject(),
		}
	},
}
</script>
