<template>
	<div class="user-show">
		<div class="flex">
			<router-link :to="{ name: 'app.users' }" class="user-previous-btn">
				<span>
					<font-awesome-icon
						fixed-width
						:icon="['far', 'arrow-to-left']"
						aria-hidden="true"
					/>
				</span>
				Back
			</router-link>
		</div>
		<user-create-form />
	</div>
</template>

<script>
import UserCreateForm from '@/components/Forms/UserCreateForm.vue'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		UserCreateForm,
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Create',
}
</script>
