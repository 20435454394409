<template>
	<div class="table-responsive">
		<table class="table">
			<thead>
				<tr>
					<th>Partner</th>
					<th class="text-center">User</th>
					<th class="text-center">Office Manager</th>
					<th class="text-center">Admin</th>
					<th />
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>
						<button
							v-if="availablePartners.length >= rows.length"
							class="btn"
							@click.prevent="onPartnerClick"
						>
							<div class="btn-ripple"></div>
							<font-awesome-icon
								class="btn-icon"
								:icon="['far', 'plus']"
							/>
							<span class="btn-label">
								Partner
							</span>
						</button>
					</td>
					<td class="text-center">
						<input
							v-model="role"
							data-tooltip="Select All"
							type="radio"
							name="role"
							value="user"
						/>
					</td>
					<td class="text-center">
						<input
							v-model="role"
							data-tooltip="Select All"
							type="radio"
							name="role"
							value="office_manager"
						/>
					</td>
					<td class="text-center">
						<input
							v-model="role"
							data-tooltip="Select All"
							type="radio"
							name="role"
							value="partner-admin"
						/>
					</td>
					<td />
				</tr>
				<user-role-row
					v-for="row in rows"
					:key="row.uuid"
					:role="row"
					:available-partners="availablePartners"
					@remove="onRemoveRow"
					@partner:change="onPartnerChange"
					@role:change="onRoleChange"
				/>
			</tbody>
		</table>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Str from 'App/Support/Str'
import UserRoleRow from './UserRoleRow.vue'

/**
 * The user role value.
 *
 * @type {String}
 */
const USER_ROLE = 'user'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		UserRoleRow,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the available partners.
		 *
		 * @return {Array}
		 */
		availablePartners() {
			return this.partnerAdminPartners.filter(partner => {
				return !this.rows.some(row => row.partner_id === partner.id)
			})
		},

		...mapGetters({
			partnerAdminPartners: 'auth/getPartnerAdminPartners',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Emit the roles change event.
		 *
		 * @return {void}
		 */
		emitRolesChange() {
			const roles = this.rows.map(row => {
				return {
					id: row.id,
					name: row.name,
					partner_id: parseInt(row.partner_id),
				}
			})

			this.$emit('roles:change', roles)
		},

		/**
		 * Synchronize the role value.
		 *
		 * @param {Array}  partnersUserRole
		 * @param {Array}  rows
		 *
		 * @return {Array}
		 */
		syncUserRoles(partnersUserRole, rows) {
			partnersUserRole.forEach(partner => {
				rows.push({
					name: USER_ROLE,
					uuid: Str.uuid(),
					partner_id: partner,
				})
			})

			return rows
		},

		/**
		 * Update the given roles.
		 *
		 * @return {void}
		 */
		updateRoles() {
			if (!this.roles) {
				return
			}

			const nonUserRoles = this.roles.map(role => role.partner_id)

			const userRoles = this.partners.filter(
				partner => !nonUserRoles.includes(partner)
			)

			let rows = this.roles.map(role => {
				return {
					...role,
					uuid: Str.uuid(),
				}
			})

			this.rows = this.syncUserRoles(userRoles, rows)
		},

		/**
		 * Handle the partner click event.
		 *
		 * @return {void}
		 */
		onPartnerClick() {
			this.rows.push({
				uuid: Str.uuid(),
				id: 'user',
				partner_id: null,
			})
		},

		/**
		 * Handle the partner change event.
		 *
		 * @param {String} uuid
		 * @param {Number} value
		 * @return {void}
		 */
		onPartnerChange({ uuid, value }) {
			const index = this.rows.findIndex(row => row.uuid === uuid)

			if (index !== -1) {
				this.rows[index].partner_id = value
			}

			this.emitRolesChange()
		},

		/**
		 * Handle the role change event.
		 *
		 * @param {String} uuid
		 * @param {String} name
		 * @param {Number} id
		 * @return {void}
		 */
		onRoleChange({ uuid, name, id }) {
			this.role = null

			const index = this.rows.findIndex(row => row.uuid === uuid)

			if (index !== -1) {
				this.rows[index].id = id
				this.rows[index].name = name
			}

			this.emitRolesChange()
		},

		/**
		 * Handle the remove row event.
		 *
		 * @param {String} uuid
		 * @return {void}
		 */
		onRemoveRow(uuid) {
			const index = this.rows.findIndex(row => row.uuid === uuid)

			if (index !== -1) {
				this.rows.splice(index, 1)
			}

			this.emitRolesChange()
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'UserRoleTable',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The user's partners.
		 *
		 * @type {Array}
		 */
		partners: {
			type: Array,
			default() {
				return []
			},
		},

		/**
		 * The roles to be shown.
		 *
		 * @type {Array}
		 */
		roles: {
			type: Array,
			default() {
				return []
			},
		},
	},

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the role for changes.
		 *
		 * @return {void}
		 */
		role() {
			if (!this.role) {
				return
			}

			this.rows.forEach(row => {
				row.id = this.role
			})
		},

		/**
		 * Watch the roles for changes.
		 *
		 * @type {Object}
		 */
		roles: {
			immediate: true,
			handler: 'updateRoles',
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			rows: [],
			role: null,
		}
	},
}
</script>
