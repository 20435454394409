<template>
	<tr>
		<td>
			<div class="relative">
				<select
					:id="`role-${role.uuid}`"
					v-model="partner"
					required
					name="partner"
					placeholder="Select partner"
					class="form-input has-trailing-icon"
					:class="[partner ? 'text-gray-700' : 'text-gray-500']"
				>
					<option v-if="!partner" :value="null" disabled>
						Select partner
					</option>
					<option v-else :value="partner">
						{{ find(partner).name }}
					</option>
					<option
						v-for="partner in availablePartners"
						:key="partner.id"
						:value="partner.id"
					>
						{{ partner.name }}
					</option>
				</select>
				<div class="trailing-icon">
					<font-awesome-icon
						class="fill-current h-4 w-4"
						:icon="['far', 'chevron-down']"
					></font-awesome-icon>
				</div>
			</div>
		</td>
		<td class="text-center">
			<input
				v-model="currentRole"
				required
				type="radio"
				value="user"
				:name="role.uuid"
			/>
		</td>
		<td class="text-center">
			<input
				v-model="currentRole"
				required
				type="radio"
				:name="role.uuid"
				value="office_manager"
			/>
		</td>
		<td class="text-center">
			<input
				v-model="currentRole"
				required
				type="radio"
				:name="role.uuid"
				value="partner-admin"
			/>
		</td>
		<td class="text-center">
			<button
				type="button"
				aria-label="Add row"
				class="btn btn-sm"
				@click.prevent="onRemoveRow(role.uuid)"
			>
				<div class="btn-ripple"></div>
				<font-awesome-icon
					fixed-width
					:icon="['fal', 'trash-alt']"
					aria-hidden="true"
				/>
			</button>
		</td>
	</tr>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * The row partner value.
		 *
		 * @type {Object}
		 */
		partner: {
			/**
			 * Get the row partner.
			 *
			 * @return {String}
			 */
			get() {
				return this.role.partner_id
			},

			/**
			 * Set the row partner.
			 *
			 * @param {String} value
			 * @return {void}
			 */
			set(value) {
				if (!value) {
					return
				}

				this.$emit('partner:change', {
					uuid: this.role.uuid,
					value: value ? value : null,
				})
			},
		},

		/**
		 * The row role value.
		 *
		 * @type {Object}
		 */
		currentRole: {
			/**
			 * Get the row role.
			 *
			 * @return {String}
			 */
			get() {
				return this.role.name
			},

			/**
			 * Set the row role.
			 *
			 * @param {String} name
			 * @return {void}
			 */
			set(name) {
				this.$emit('role:change', {
					id: this.role.id,
					uuid: this.role.uuid,
					name: name ? name : null,
				})
			},
		},

		...mapGetters({
			find: 'partners/find',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Handle the remove row event.
		 *
		 * @param {Number} id
		 * @return {void}
		 */
		onRemoveRow(id) {
			this.$emit('remove', id)
		},
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'UserRoleRow',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * The available partners to be shown.
		 *
		 * @type {Array}
		 */
		availablePartners: {
			type: Array,
			required: true,
		},

		/**
		 * The role to be displayed.
		 *
		 * @type {Object}
		 */
		role: {
			type: Object,
			required: true,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			partners: [],
		}
	},
}
</script>
